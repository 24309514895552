<template>
  <div class="route-map">
    <div class="map-container" :style="{height: mapHeight}" >
      <div id="route-map">
        <span class="loading-text">Loading</span>
      </div>
    </div>
  </div>
</template>

<script>
const currentMarkerAnimationData = {
  numDeltas: 100,
  delay: 10,
  i: 0,
  deltaLat: 0,
  deltaLng: 0,
  position: []
}

function toRad (val) {
  return val * Math.PI / 180;
}

function toDeg (val) {
  return val * 180 / Math.PI;
}

export default {
  name: 'RouteMap',
  props: {
    mapHeight: {
      type: String,
      default: null
    },
    startDateLocal: {
      type: String,
      default: null
    },
    coordinates: {
      type: Array,
      default: null
    },
    traveledDistance: {
      type: Number,
      default: 0
    },
    lastRideFinishCoordinates: {
      type: Object,
      default: null
    },
    currentSecond: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: 'ready'
    }
  },
  async mounted() {
    await this.$store.dispatch('loadGMaps')
    setTimeout(() => {
      this.setGooglePrototypes()
      this.init()
    }, 500)
  },
  data() {
      return {
        map: null,
        bounds: null,
        currentPositionMarker: null,
        polyLines: {
          main: null,
          traveled: null
        },
        mapListeners: {
          zoom: null
        },
        routePoints: [],
        routeMovementPoints: [],
        lastRideFinishPointIndex: 0,
        currentPositionMarkerDirection: 'direct',
        currentRideData: {
          coordinates: [],
          traveledDistance: 0
        }
      }
    },
  watch: {
    lastRideFinishCoordinates() {
      this.resetRide()
    },
    status(status) {
      // on start
      if (status === 'discard') {
        this.resetRide()
      }

      if (status === 'saving' ) {
        this.$emit('saveRoute', {...this.currentRideData, startDateLocal: this.startDateLocal })
      }

      if (status === 'ready') {
        this.resetRide()
      }
    },
    traveledDistance(distance) {
      if (distance === 0) {
        return
      }
      const nextPoint = this.findNextPointPosition(distance)
      const zoom = this.map.getZoom()
      this.moveCurrentPositionMarker(nextPoint)
      this.addPointToTraveledPolyline(nextPoint)

      if (zoom >= 19) {
        const currentPoint = this.currentPositionMarker.getPosition()
        const head = google.maps.geometry.spherical.computeHeading(currentPoint, nextPoint);
        this.map.setCenter(nextPoint)
        this.map.setHeading(head)
      }

      if (zoom < 19) {
        const bounds = this.map.getBounds()
        if (!bounds.contains(nextPoint)) {
          this.map.panTo(nextPoint)
        }
        if (this.map.getHeading() !== 0) {
          this.map.setHeading(0)
        }
      }

      this.currentRideData.coordinates.push(nextPoint.toJSON())
      this.currentRideData.traveledDistance = distance
    }
  },
  computed: {
    startPosition() {
      if (this.lastRideFinishCoordinates) {
        const { lat, lng } = this.lastRideFinishCoordinates
        return new google.maps.LatLng(lat, lng)
      }

      const [startPoint] = this.routePoints
      return startPoint
    }
  },
  methods: {
    findNextPointPosition(distance) {
        let nextPointPosition = this.moveAlongPath(this.routeMovementPoints, distance)

        if (!nextPointPosition) {
          // if the marker has reached the end, then add the following coordinates in reverse order
          if (this.currentPositionMarkerDirection === 'direct') {
            this.routeMovementPoints = this.routeMovementPoints.concat([ ...this.routePoints ].reverse())
            this.currentPositionMarkerDirection = 'back'
          }

          if (this.currentPositionMarkerDirection === 'back') {
            this.routeMovementPoints = this.routeMovementPoints.concat([ ...this.routePoints ])
            this.currentPositionMarkerDirection = 'direct'
          }

          nextPointPosition = this.moveAlongPath(this.routeMovementPoints, distance)
        }

        return nextPointPosition
      },

    smoothZoom(map, max, cnt) {
        if (cnt === max) {
          return
        }
        const z = google.maps.event.addListener(map, 'zoom_changed', () => {
          google.maps.event.removeListener(z);
          if (cnt >= max) {
            this.smoothZoom(map, max, cnt - 1);
          } else {
            this.smoothZoom(map, max, cnt + 1);
          }
        });
        setTimeout(function(){map.setZoom(cnt)}, 80)
      },

    moveAlongPath(points, distance, index) {
        index = index || 0;  // Set index to 0 by default.

        if (index < points.length && ((index + 1) < points.length)) {
          // There is still at least one point further from this point.

          // Construct a GPolyline to use its getLength() method.
          const polyline = new google.maps.Polyline({
            path: [points[index], points[index + 1]],
            geodesic: false
          })

          // Get the distance from this point to the next point in the polyline.
          // const distanceToNextPoint = polyline.getPath().getLength();
          const distanceToNextPoint = google.maps.geometry.spherical.computeLength(polyline.getPath());


          if (distance <= distanceToNextPoint) {
            // distanceToNextPoint is within this point and the next.
            // Return the destination point with moveTowards().
            return points[index].moveTowards(points[index + 1], distance);
          } else {
            // The destination is further from the next point. Subtract
            // distanceToNextPoint from distance and continue recursively.
            return this.moveAlongPath(points,
                distance - distanceToNextPoint,
                index + 1);
          }
        } else {
          // There are no further points. The distance exceeds the length
          // of the full path. Return null.
          return null;
        }
      },

    moveCurrentPositionMarker(gPoint) {
        const { numDeltas, position } = currentMarkerAnimationData
        currentMarkerAnimationData.i = 0;
        currentMarkerAnimationData.deltaLat = (gPoint.lat() - position[0]) / numDeltas;
        currentMarkerAnimationData.deltaLng = (gPoint.lng() - position[1]) / numDeltas;
        this.definePositionCurrentMarker()
      },

    definePositionCurrentMarker() {
        currentMarkerAnimationData.position[0] += currentMarkerAnimationData.deltaLat;
        currentMarkerAnimationData.position[1] += currentMarkerAnimationData.deltaLng;

        const gPoint = new google.maps.LatLng(currentMarkerAnimationData.position[0], currentMarkerAnimationData.position[1]);
        this.currentPositionMarker.setPosition(gPoint);
        if(currentMarkerAnimationData.i !== currentMarkerAnimationData.numDeltas){
          currentMarkerAnimationData.i++;
          setTimeout(this.definePositionCurrentMarker, currentMarkerAnimationData.delay);
        }
      },

    addCurrentPositionMarker() {
        this.currentPositionMarker = new google.maps.Marker({
          position: this.startPosition,
          map: this.map,
          visible: true,
          icon: {
            url: require('@/assets/img/bike-marker.svg'),
            anchor: new google.maps.Point(20, 40),
          }
        })
        currentMarkerAnimationData.position = [this.startPosition.lat(), this.startPosition.lng()]
      },

    addStartFinishMarkers() {
        const [startPosMarker] = this.routePoints
        const endPosMarker = this.routePoints[this.routePoints.length - 1]

        const icon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          strokeColor: '#ffffff',
          fillOpacity: 1,
          strokeWeight: 1
        }
        const startMarker = new google.maps.Marker({
          position: startPosMarker,
          icon: { ...icon, fillColor: '#3fcf7c'}
        })

        const finishMarker = new google.maps.Marker({
          position: endPosMarker,
          icon: { ...icon, fillColor: '#FE5959'}
        })

        startMarker.setMap(this.map)
        finishMarker.setMap(this.map)
      },

    addPolyLines() {
        const border = new google.maps.Polyline({
          path: this.routePoints,
          geodesic: false,
          strokeColor: '#1967d2',
          strokeOpacity: 1.0,
          strokeWeight: 8
        })
        border.setMap(this.map)

        this.polyLines.main = new google.maps.Polyline({
          path: this.routePoints,
          map: this.map,
          geodesic: false,
          strokeColor: '#438bec',
          strokeOpacity: 1.0,
          strokeWeight: 4
        })

        this.polyLines.traveled = new google.maps.Polyline({
          path: this.lastRideFinishPointIndex ? this.routePoints.slice(0, this.lastRideFinishPointIndex + 1) : [],
          map: this.map,
          geodesic: false,
          strokeColor: '#8ab5ef',
          strokeOpacity: 1.0,
          strokeWeight: 4
        })
      },

    addPointToTraveledPolyline(gPoint) {
      const path = this.polyLines.traveled.getPath()
      path.push(gPoint)
      this.polyLines.traveled.setPath(path)
    },

    resetRide() {
      if (this.lastRideFinishCoordinates) {
        const { lat, lng } = this.lastRideFinishCoordinates
        const lastRideFinishPoint = new google.maps.LatLng(lat, lng)
        this.lastRideFinishPointIndex = this.routePoints.findIndex((routePoint) => {
          const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(routePoint, lastRideFinishPoint);
          return distanceInMeters < 2
        })
        this.routeMovementPoints = [ ...this.routePoints.slice(this.lastRideFinishPointIndex + 1) ]
      } else {
        this.routeMovementPoints = [ ...this.routePoints ]
      }

      const path = this.lastRideFinishPointIndex ? this.routePoints.slice(0, this.lastRideFinishPointIndex + 1) : [this.routePoints[0]]
      this.polyLines.traveled.setPath(path)

      this.currentRideData.coordinates = []
      this.currentRideData.traveledDistance = 0
      this.moveCurrentPositionMarker(this.startPosition)
      this.map.setCenter(this.startPosition)
      const head = google.maps.geometry.spherical.computeHeading(this.startPosition, this.routeMovementPoints[3]);
      this.map.setHeading(head)
    },

    init() {
      this.routePoints = this.coordinates.map(({ lat, lng }) => new google.maps.LatLng(lat, lng))
      this.routeMovementPoints = [ ...this.routePoints ]

      if (this.lastRideFinishCoordinates) {
          const { lat, lng } = this.lastRideFinishCoordinates
          const lastRideFinishPoint = new google.maps.LatLng(lat, lng)
          this.lastRideFinishPointIndex = this.routePoints.findIndex((routePoint) => {
            const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(routePoint, lastRideFinishPoint);
            return distanceInMeters < 3
          })
          this.routeMovementPoints = [ ...this.routePoints.slice(this.lastRideFinishPointIndex + 1) ]
        }

      this.bounds = new google.maps.LatLngBounds()
      this.map = new google.maps.Map(document.getElementById('route-map'), {
          mapId: "ecfe3a700326e8df",
          tilt: 0,
          heading: 0,
          zoom: 16,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: true,
          center: this.startPosition
        })

      this.addMapListeners()
      this.addCurrentPositionMarker()
      this.addPolyLines()
      this.addStartFinishMarkers()
      this.addMapControls()

      for (let i = 0; i < this.routePoints.length; i++) {
          this.bounds.extend(this.routePoints[i])
        }

      this.map.fitBounds(this.bounds, 0)

      setTimeout(() => {
          const currentPoint = this.currentPositionMarker.getPosition()
          const nextPoint = this.routeMovementPoints[5] || this.routeMovementPoints[0]
          const head = google.maps.geometry.spherical.computeHeading(currentPoint, nextPoint);
          this.map.moveCamera({
            zoom: 19,
            heading: head,
            tilt: 68,
            center: currentPoint
          })
        }, 3000)
    },

    addMapControls() {
      const fullscreenBtn = document.createElement("button");
      fullscreenBtn.style.backgroundColor = "#fff";
      fullscreenBtn.style.border = "2px solid #fff";
      fullscreenBtn.style.borderRadius = "2px";
      fullscreenBtn.style.boxShadow = "rgb(0 0 0 / 30%) 0px 1px 4px -1px";
      fullscreenBtn.style.color = "rgb(25,25,25)";
      fullscreenBtn.style.cursor = "pointer";
      fullscreenBtn.style.margin = "5px";
      fullscreenBtn.style.width = "32px";
      fullscreenBtn.style.height = "32px";
      fullscreenBtn.style.padding = "5px";
      fullscreenBtn.type = "button";
      fullscreenBtn.addEventListener("click", () => {
        this.$emit('expandMap')
      });

      const fullscreenBtnImg = document.createElement('img')
      fullscreenBtnImg.src = 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E'
      fullscreenBtnImg.style.height = '18px'
      fullscreenBtnImg.style.width = '18px'
      fullscreenBtn.appendChild(fullscreenBtnImg)
      const div = document.createElement("div");
      div.appendChild(fullscreenBtn);
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(div);
    },

    addMapListeners() {
        this.mapListeners.zoom = this.map.addListener('zoom_changed', () => {
          const zoom = this.map.getZoom()
          const tilt = this.map.getTilt()
          if (zoom >= 19 && tilt === 0) {
            this.map.setTilt(68.8)
          }
          if (zoom < 19 && tilt !== 0) {
            this.map.setTilt(0)
          }
        });
      },

    setGooglePrototypes() {
        // https://stackoverflow.com/questions/2698112/how-to-add-markers-on-google-maps-polylines-based-on-distance-along-the-line
        google.maps.LatLng.prototype.moveTowards = function (point, distance) {
          const lat1 = toRad(this.lat());
          const lon1 = toRad(this.lng());
          let lat2 = toRad(point.lat());
          let lon2 = toRad(point.lng());

          const dLon = toRad((point.lng() - this.lng()));

          // Find the bearing from this point to the next.
          const brng = Math.atan2(Math.sin(dLon) * Math.cos(lat2),
              Math.cos(lat1) * Math.sin(lat2) -
              Math.sin(lat1) * Math.cos(lat2) *
              Math.cos(dLon));

          const angDist = distance / 6371000;  // Earth's radius.

          // Calculate the destination point, given the source and bearing.
          lat2 = Math.asin(Math.sin(lat1) * Math.cos(angDist) +
              Math.cos(lat1) * Math.sin(angDist) *
              Math.cos(brng));

          lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(angDist) *
              Math.cos(lat1),
              Math.cos(angDist) - Math.sin(lat1) *
              Math.sin(lat2));

          if (isNaN(lat2) || isNaN(lon2)) return null;

          return new google.maps.LatLng(toDeg(lat2), toDeg(lon2))
        }
      }
  },
  beforeDestroy() {
    google.maps.event.removeListener(this.mapListeners.zoom)
  }
}
</script>

<style lang="scss">
  .route-map {
    border-radius: 6px;
    overflow: hidden;
    .map-container {
      width: 100%;
      height: 180px;
      overflow: hidden;
      .gm-ui-hover-effect {display: none !important;}
    }
    #route-map {
      position: relative;
      width: 100%;
      height: 100%;
      .loading-text {
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 10px);
      }
    }
  }
</style>
