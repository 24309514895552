import Home from "@/Home"
import Provider from "@/Provider"
import Client from "@/Client"
import VueRouter from "vue-router"
const routes = [
  {
    path: "",
    name: "home",
    component: Home,
  },
  {
    path: "/provider/:userId",
    name: "provider",
    component: Provider,
  },
  {
    path: "/:userId",
    name: "client",
    component: Client,
  },
]
const router = new VueRouter({
  routes,
  mode: "history",
})

export default router
