<template>
  <div v-bind:class="{ height: !chartInBottomBar }" class="chart-height">
    <canvas ref="canvas" style="width: 100%; background: #000000"></canvas>
    <a
        v-if="bpm.length && !chartInBottomBar"
        href="#"
        class="chart-hr-toggle"
        :class="{'active': showHRDiagram }"
        @click.prevent="showHRDiagram = !showHRDiagram"
    >
      <svg-icon icon="heartbeat"/>
    </a>
  </div>
</template>

<script>
import canvasChart from "./canvasChart"
import { mapState } from "vuex"

export default {
  name: "CanvasChart",
  mixins: [canvasChart],
  props: ["bpm", "rpm", "ftp", "thresholdHR", "watts", "showWattsDiagram", "segments"],
  data() {
    return {
      showHRDiagram: true
    }
  },
  computed: {
    ...mapState(["user"]),
    chartInBottomBar() {
      return this.user.chartInBottomBar
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-hr-toggle {
  position: absolute;
  top: 7px;
  right: 2px;
  .icon {
    color: #A5A9AC;
  }

  &.active .icon {
    color: #b91911;
  }
}
.chart-height {
  position: relative;
  margin: 8px 0 8px 16px;
  flex: 1;
}
.height {
  margin: 16px 16px 0 16px;
  height: 63%;
}
@media (max-height: 700px) {
  .height {
    height: 53%;
  }
}
@media (max-height: 520px) {
  .height {
    height: 45%;
  }
}
@media (max-height: 475px) {
  .height {
    display: none;
  }
}
</style>
