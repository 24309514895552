<template>
  <div id="app">
    <img class="svg-sprite" id="svg-sprite" src="./assets/img/sprite.svg" alt="svg-sprite">
    <router-view />
    <confirm-modal ref="confirmModal" />
  </div>
</template>

<script>
import Vue from "vue";
import ConfirmModal from "@/components/ConfirmModal"

export default {
  name: "App",
  components: {
    ConfirmModal
  },
  mounted() {
    Vue.prototype.$confirmModal = this.$refs.confirmModal;
  }
}
</script>

<style lang="scss">
html,
body {
  background-color: #20232f;
  height: 100%;
  margin: 0;
}

.svg-sprite {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
