<template>
  <div>
    <div>
      <h2 style="color: #ffffff">Welcome TrainerDay Live</h2>
      <img src="favicon.png" alt="icon">
    </div>
    <template v-if="!isProd">
      <br>
      <input type="text" v-model="userId" />
      <br />
      <router-link class="items" target="_blank" :to="{ name: 'provider', params: { userId } }">Provider</router-link>
      <br />
      <router-link class="items" target="_blank" :to="{ name: 'client', params: { userId } }">Client</router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "HomePage",

  data() {
    return {
      userId: null,
      isProd: false,
    }
  },
  beforeCreate() {
    const { APP_ENV } = process.env
    if (APP_ENV === 'production') {
      this.isProd = true
      window.location.href = 'https://blog.trainerday.com/view-your-live-trainerday-training-on-a-big-screen-38745c7388b4'
    }
  }
}
</script>

<style scoped>
.items {
  margin: 50px;
  font-size: 30px;
}
:visited {
  color: white; /* Цвет посещенной ссылки */
}
</style>
