<template>
  <div>
    <p class="text">Provider Emulator</p>
    <p class="text">User ID: {{ userId }}</p>
    <p class="text">Second: {{ second }}</p>
    <input type="text" v-model="target" /><br />
    <input type="text" v-model="interval" />
  </div>
</template>

<script>
import activity from "@/data/activity"
import configuration from "./configuration"
const { websocketsUrl } = configuration
const { io } = require("socket.io-client")

export default {
  name: "DataProvider",

  data() {
    return {
      userId: null,
      second: 1,
      target: 55,
      interval: "5:00",
      history: {
        rpm: [],
        bpm: [],
        ftp: null,
        watts: [],
        segments: [],
      },
    }
  },
  methods: {
    init() {
      const { userId } = this.$route.params
      this.userId = userId

      const socket = io(websocketsUrl)

      socket.on("connect", () => {
        socket.emit("login", { userId, isProvider: true })

        for (const i in activity) {
          setTimeout(() => {
            const data = activity[i]
            if (data.statusActivity === "ready") {
              socket.emit("clearHistory")
              // this.history = { rpm: [], bpm: [], watts: [], segments:[]}
            }

            this.second = activity[i].currentSecond
            this.history.watts.push(data.watts)
            this.history.bpm.push(data.bpm)
            this.history.rpm.push(data.rpm)
            this.history.ftp = data.ftp
            this.history.segments = data.workout.segments
            socket.emit("data", data)
          }, 1000 * i)
        }
      })

      socket.on("getHistory", () => {
        socket.emit("sendHistory", this.history)
      })
      socket.on("disconnecting", () => {
        socket.emit("clearHistory")
      })
    },
  },
  created() {
    const { APP_ENV } = process.env
    if (APP_ENV === 'production') {
      window.location.href = 'https://blog.trainerday.com/view-your-live-trainerday-training-on-a-big-screen-38745c7388b4'
      return
    }
    this.init()
  },
}
</script>

<style scoped>
.text {
  margin: 50px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}
</style>
