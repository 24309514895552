<template>
  <div class="root">
    <div class="video__container">
      <div class="video__container__left">
        <button class="button-back" @click="showChart">
          <ButtonBack />
        </button>
      </div>
      <div class="video__container__middle">
        <div class="title">Add video URL</div>
        <input
          type="text"
          class="input background-items"
          placeholder="https://www.youtube.com/watch?v=igp9sJkuAnU"
          v-model="inputUrl"
          @keyup.enter="addVideo(inputUrl)"
        />
        <button class="add-video-button" @click="addVideo(inputUrl)">Add video</button>
      </div>
      <div class="video__container__right">
        <div class="title">Select your video</div>
        <div class="list_wrapper">
          <div v-if="!videoList.length && user.isLogged" class="list_wrapper_noVideos">
            You have not added any video yet
          </div>
          <div class="list_container" v-for="(video, index) in videoListValue" :key="index">
            <div class="list__item__row">
              <div @click="sendVideoUrl(index)" class="list__item background-items">
                <div class="list__item__title">{{ video.title }}</div>
                <div class="list__item__time">{{ video.duration }}</div>
              </div>
              <div>
                <button
                  v-if="
                    user.isLogged && video.title != '30 Second Sprints HIIT Sandwich Session | Indoor Cycling Workout'
                  "
                  class="list__item__delete"
                  @click="deleteVideo(video.url)"
                >
                  <ButtonDelete />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBack from "@/components/ButtonBack"
import ButtonDelete from "@/components/ButtonDelete"
import { mapState, mapActions } from "vuex"
import VueCookies from "vue-cookies"

export default {
  data() {
    return {
      inputUrl: "",
    }
  },
  components: {
    ButtonBack,
    ButtonDelete,
  },
  props: ["youtubeFrame"],
  name: "VideosList",
  computed: {
    ...mapState(["videoList", "user"]),
    videoListValue() {
      return this.videoList
    },
  },

  async mounted() {
    this.token = VueCookies.get("token")
  },
  methods: {
    ...mapActions(["getVideoList", "addVideo", "showChart"]),
    swapComponent() {
      this.$emit("clickToSwapComponent")
    },
    showChart() {
      this.$store.dispatch("showChart")
    },
    async addVideo(inputUrl) {
      if (inputUrl === "") {
        await this.checkError("Link can't be empty")
        return
      }
      const isVideoExist = this.videoList.find((el) => {
        return this.inputUrl.includes(el.url)
      })

      if (!isVideoExist) {
        const { title } = await this.$store.dispatch("addVideo", { token: this.token, inputUrl })
        if (title === "Error" || title === "TypeError" || !title) {
          await this.checkError("Link not valid")
        }
      } else {
        await this.checkError("Video already exist")
      }
      this.inputUrl = ""
    },
    async checkError(errorText) {
      this.inputUrl = errorText
      await new Promise((resolve) => setTimeout(resolve, 1000))
      setTimeout(() => {
        this.inputUrl = ""
      }, 0)
    },
    async deleteVideo(url) {
      await this.$store.dispatch("deleteVideo", { token: this.token, url })
    },
    sendVideoUrl(index) {
      this.$emit("videoUrl", this.videoList[index].url)
    },
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import "../assets/styles/videoList.css";
</style>
