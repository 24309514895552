import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import VueRouter from "vue-router"
import store from "@/store"
import VueCookies from "vue-cookies"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import SVGIcon from '@/components/SVGIcon'
import ScreenSizePlugin from '@/utils/ScreenSizePlugin'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(ScreenSizePlugin)
Vue.component('svg-icon', SVGIcon)

if (process.env.APP_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://1f265d45d49a4767adf562b318e22a6d@o230172.ingest.sentry.io/6760719",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "trainerday.com", "app.trainerday.com", "coachjack.trainerday.com", /^\//],
      }),
    ],
    tracingOptions: {
      trackComponents: true,
    },
    tracesSampleRate: 1.0,
  })
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
