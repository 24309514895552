<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://svgjs.com/svgjs "
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z"
        fill="#000000"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z"
        fill="#000000"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"
        fill="#000000"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"
        fill="#000000"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ButtonDelete",
}
</script>

<style scoped></style>
