const {
  wPrimeBalanceFroncioniSkibaClarke,
  getTrainingStress,
  getIntensityFactor,
} = require("@trainerday/cycling-metrics")
const { getTimeArray } = require("./getTimeArray")
const { scaleLinear } = require("d3-scale")
const { extent } = require("d3-array")
const { area, line } = require("d3-shape")

// const LABELS_COLOR = '#c2c2c2' // '#D7DADF'
const LINE_GRAY_DARK = "#5B5D66"
const LINE_GRAY_LIGHT = "#5B5D66"
const LINE_YELLOW = "#FFCF74"
const BACKGROUND_DARK = "#070707"
const BACKGROUND_LIGHT = "#070707"
const LABELS_COLOR = "#FFFFFF"

const RED_COLOR = "#e7140f"
const FTP_SEGMENT_COLOR = "#2C68DE"
const HR_SEGMENT_COLOR = "#ef4d4d"

module.exports = class Chart {
  constructor(props) {
    const {
      segments,
      watts,
      bpm,
      ftp,
      thresholdHR = 140,
      chartType = "default",
      criticalPower,
      wPrime,
      canvas,
      width,
      height,
      maxValueY,
      cursorLineX = null,
      theme = "light",
      showWattsDiagram = false,
      showHRDiagram = false,
      showWBal = false,
      isHRSegments = false,
      chartAreaBackgroundColor = "#070707",
    } = props

    const isMiniChart = chartType === "mini"
    this.segments = segments
    this.watts = watts
    this.bpm = bpm
    this.ftp = ftp
    this.thresholdHR = thresholdHR
    this.criticalPower = criticalPower
    this.wPrime = wPrime
    this.showWattsDiagram = showWattsDiagram
    this.showHRDiagram = showHRDiagram
    this.showWBal = showWBal
    this.isDarkTheme = theme === "dark"
    this.isHRSegments = isHRSegments
    this.padding = {
      top: 0,
      right: 0,
      bottom: isMiniChart ? 0 : 27,
      left: isMiniChart ? 0 : 27,
    }
    this.width = width
    this.height = height
    this.cursorLineX = cursorLineX - this.padding.left

    const isMaxValueYNotSet = !maxValueY

    this.maxValueY = isMaxValueYNotSet ? 200 : maxValueY
    this.canvas = canvas
    this.context = this.canvas.getContext("2d")
    const { devicePixelRatio: scale = 1 } = window

    canvas.width = Math.floor(width * scale)
    canvas.height = Math.floor(height * scale)

    this.context.scale(scale, scale)

    if (!isMiniChart) {
      this.context.fillStyle = this.isDarkTheme ? BACKGROUND_DARK : BACKGROUND_LIGHT
    } else {
      this.context.fillStyle = "#FFFFFF"
    }

    this.context.fillRect(0, 0, this.width, this.height)

    if (!isMiniChart) {
      this.linesY.forEach((e) => {
        this.drawLine(e)
      })
    }

    this.drawWorkoutArea()
    this.context.fillStyle = chartAreaBackgroundColor

    if (!isMiniChart) {
      this.context.fillRect(0, 0, this.padding.left, this.height)
      this.context.fillRect(0, 0, 0, 0)
      this.context.fillRect(0, this.height - this.padding.bottom, this.width, this.padding.bottom)
    }

    if (this.showWattsDiagram && this.watts) {
      this.drawWattsLine()
      this.drawWattsArea()
    }

    if (this.showHRDiagram) {
      this.drawHRArea()
    }

    if (this.showWBal) {
      this.drawWBalLineArea()
    }

    if (!isMiniChart) {
      this.drawLabelsY()
      this.drawLabelsX()
    }

    this.drawProgressVerticalLine()

    this.drawStressAndIFLabels()
  }

  async getData() {
    this.context.fillStyle = this.isDarkTheme ? BACKGROUND_DARK : BACKGROUND_LIGHT

    return this.canvas
  }

  get leftYAxisValues() {
    return {
      min: 0,
      max: this.maxValueY,
    }
  }

  get rightYAxisValues() {
    return {
      min: 0,
      max: Math.floor(this.thresholdHR * 2),
    }
  }

  get xFunc() {
    // if you remove segmentsData below it wouldn't work
    // eslint-disable-next-line no-unused-vars
    const { width, padding, segmentsData } = this

    return scaleLinear()
      .domain(extent([0, this.maxTimeValue]))
      .range([padding.left, width - padding.right])
  }

  get yFunc() {
    const { height, padding } = this

    const extraTop = (height - padding.bottom - padding.top) / 9 // 8 lines + 1 extra

    return scaleLinear()
      .domain([this.leftYAxisValues.min, this.leftYAxisValues.max])
      .range([height - padding.bottom, padding.top + extraTop])
  }

  get yFuncRight() {
    const extraTop = (this.height - this.padding.bottom - this.padding.top) / 9 // 8 lines + 1 extra
    return scaleLinear()
      .domain([this.rightYAxisValues.min, this.rightYAxisValues.max])
      .range([this.height - this.padding.bottom, this.padding.top + extraTop])
  }

  get cursorX() {
    let maxTimeInMin
    if (!this.segments || !this.segments.length) {
      return 0
    }

    if (this.segments.length === 1) {
      const [minutes] = this.segments[0]
      maxTimeInMin = minutes
    } else {
      maxTimeInMin = this.segments.reduce((prev, curr) => (prev[0] || prev) + (curr[0] || 0))
    }

    const maxTimeInSec = maxTimeInMin * 60
    const pixelsPerSecond = this.chartWidth / maxTimeInSec
    return this.cursorLineX / pixelsPerSecond
  }

  get chartWidth() {
    const { padding, width } = this
    return width ? width - padding.right - padding.left : 0
  }

  get linesY() {
    const minVal = 0
    const middleVal = this.ftp
    const maxVal = Math.floor(middleVal * 2)

    const width = this.chartWidth
    const x = this.xFunc(0)

    const fill = this.isDarkTheme ? LINE_GRAY_DARK : LINE_GRAY_LIGHT
    const isDetailed = this.height >= 200

    const boldLineHeight = 1.2
    const mediumLineHeight = 0.6
    const thinLineHeight = 0.6

    const yMin = this.yFunc(minVal)
    const yCenter = this.yFunc(middleVal)
    const yMax = this.yFunc(this.maxValueY - mediumLineHeight)

    return [
      { width, x, y: yMax, height: mediumLineHeight, fill },
      ...(isDetailed ? [{ width, x, y: this.yFunc(middleVal * 1.75), height: thinLineHeight, fill }] : []),
      { width, x, y: this.yFunc(middleVal * 1.5), height: mediumLineHeight, fill },
      ...(isDetailed ? [{ width, x, y: this.yFunc(middleVal * 1.25), height: thinLineHeight, fill }] : []),
      { width, x, y: yCenter, height: boldLineHeight, fill: LINE_YELLOW },
      ...(isDetailed ? [{ width, x, y: this.yFunc(maxVal - middleVal * 1.25), height: thinLineHeight, fill }] : []),
      { width, x, y: this.yFunc(maxVal - middleVal * 1.5), height: mediumLineHeight, fill },
      ...(isDetailed ? [{ width, x, y: this.yFunc(maxVal - middleVal * 1.75), height: thinLineHeight, fill }] : []),
      { width, x, y: yMin, height: mediumLineHeight, fill },
    ]
  }

  get segmentsData() {
    this.timeAcc = 0

    return this.segments.reduce((acc, [time, start, end]) => {
      acc.push([this.timeAcc, start])
      this.timeAcc = this.timeAcc + time
      acc.push([this.timeAcc, end || start])
      return acc
    }, [])
  }

  get wBalLineData() {
    const { watts, criticalPower, wPrime } = this
    const wPrimeBalance = wPrimeBalanceFroncioniSkibaClarke(watts, criticalPower, wPrime)
    return wPrimeBalance.map((power, currentSecond) => {
      return [currentSecond, power / 100]
    })
  }

  get maxTimeValue() {
    const actualTime = this.watts ? this.watts.length + 1 : 0

    return actualTime > this.timeAcc * 60 ? actualTime : this.timeAcc * 60
  }

  get fontSize() {
    return this.height < 120 ? 9 : 12
  }

  get labelsY() {
    const x = this.xFunc(0)
    const middle = this.ftp

    const ticks = [
      // 0,
      Math.floor(middle * 0.5),
      middle,
      Math.floor(middle * 1.5),
      Math.floor(middle * 2),
    ]

    return ticks.map((tickValue) => {
      return {
        x,
        y: this.yFunc(tickValue),
        value: tickValue,
        fill: LABELS_COLOR,
      }
    })
  }

  get labelsX() {
    const labels = getTimeArray(this.maxTimeValue / 60, 1)
    const preLastValue = labels[labels.length - 2]
    const lastValue = labels[labels.length - 1]
    if (lastValue < preLastValue) {
      labels.pop()
    }

    const half = labels.length && labels.length >= 2 ? (labels[1] - labels[0]) / 2 : 0

    return labels.reduce((acc, label, index) => {
      const nameLabel = parseFloat(Number(label).toFixed(1)).toString()

      let x = this.xFunc(label * 60)

      acc.push({
        name: nameLabel,
        x,
      })

      x = this.xFunc((label + half) * 60)

      if (x <= this.width && half) {
        acc.push({
          x,
        })
      }

      return acc
    }, [])
  }

  get rightYAxisLabels() {
    const x = this.xFunc(0)
    const middle = this.thresholdHR

    const ticks = [
      this.rightYAxisValues.min,
      Math.floor(middle * 0.5),
      middle,
      Math.floor(middle * 1.5),
      this.rightYAxisValues.max,
    ]

    return ticks.map((tickValue) => {
      return {
        x,
        y: this.yFuncRight(tickValue),
        value: tickValue,
        fill: RED_COLOR,
      }
    })
  }

  drawLabelsY() {
    // draw left labels
    this.labelsY.forEach((label) => {
      const { context } = this

      context.font = `${this.fontSize}px Poppins`
      context.textAlign = "center"
      context.textBaseline = "middle"
      context.fillStyle = label.fill

      context.fillText(label.value, label.x - this.padding.left / 2, label.y)
    })

    // draw right labels
    this.rightYAxisLabels.forEach((label) => {
      const { context } = this

      context.font = `${this.fontSize}px Poppins`
      context.textAlign = "center"
      context.textBaseline = "middle"
      context.fillStyle = label.fill

      context.fillText(label.value, label.x + (this.chartWidth - 12), label.y)
    })
  }

  drawLabelsX() {
    this.labelsX.forEach((label, i) => {
      const { context, cursorX } = this
      const thickHeight = 7
      const thickPosY = this.height - this.padding.bottom - thickHeight
      const cursorScaledX = this.xFunc(cursorX)

      const isLastLabel = this.labelsX.length - 1 === i
      const isCursorNear = cursorScaledX > label.x - 20 && cursorScaledX < label.x + 20

      context.fillStyle = LABELS_COLOR
      context.fillRect(!isLastLabel ? label.x : label.x - 1.5, thickPosY, 1, thickHeight)

      if (label.name) {
        const text = this.context.measureText(label.name)

        let textAlign = "center"

        if (this.width - label.x - text.width / 2 <= 0) {
          textAlign = "end"
        }
        context.font = `${this.fontSize}px Poppins`
        context.textAlign = textAlign
        context.textBaseline = "middle"
        if (isCursorNear) {
          context.fillStyle = "#eee"
        }
        context.fillText(label.name, label.x, thickPosY + 20)
      }
    })
  }

  drawAreaPath() {
    return area()
      .x(([time]) => this.xFunc(time * 60))
      .y0(this.yFunc(0))
      .y1(([, value]) => this.yFunc(value))
      .context(this.context)(this.segmentsData)
  }

  drawHRAreaPath() {
    return line()
      .x((value, time) => this.xFunc(time))
      .y((value) => this.yFuncRight(value))
      .context(this.context)(this.bpm)
  }

  drawWattsLine() {
    this.context.beginPath()
    line()
      .x((value, time) => this.xFunc(time))
      .y((value) => this.yFunc(value))
      .context(this.context)(this.watts)
    this.context.strokeStyle = "#b89243"
    this.context.lineWidth = 1
    this.context.stroke()
    this.context.save()
  }

  drawWattsArea() {
    this.context.beginPath()
    area()
      .x((value, time) => this.xFunc(time))
      .y0(this.yFunc(0))
      .y1((value) => this.yFunc(value))
      .context(this.context)(this.watts)
    this.context.fillStyle = "rgb(30, 44, 73, 0.5)"
    this.context.fill()
    this.context.save()
  }

  drawWorkoutArea() {
    this.context.beginPath()
    this.drawAreaPath()
    this.context.fillStyle = this.isHRSegments ? HR_SEGMENT_COLOR : FTP_SEGMENT_COLOR
    this.context.fill()
    this.context.save()
  }

  drawHRArea() {
    this.context.beginPath()
    this.drawHRAreaPath()
    this.context.strokeStyle = "rgb(172,3,28)"
    this.context.lineWidth = 1
    this.context.stroke()
    this.context.save()
  }

  drawLine(line) {
    this.context.beginPath()
    this.context.strokeStyle = line.fill
    this.context.lineWidth = line.height
    this.context.moveTo(line.x, line.y)
    this.context.lineTo(this.width - this.padding.right, line.y)
    this.context.stroke()
    this.context.restore()
  }

  drawWBalLineArea() {
    this.context.beginPath()
    this.context.setLineDash([4, 4])
    this.drawWBalLineAreaPath()
    this.context.strokeStyle = "rgb(236,6,6)"
    this.context.lineWidth = 2
    this.context.stroke()
    this.context.save()
  }

  drawWBalLineAreaPath() {
    const wBalMaxValue = this.wPrime / 100
    const x = scaleLinear()
      .domain(extent(this.wBalLineData, ([time]) => time))
      .range([this.padding.left, this.width - this.padding.right])

    const y = scaleLinear()
      .domain([0, wBalMaxValue])
      .range([this.height - this.padding.bottom, this.padding.top])

    return line()
      .x(([time]) => x(time))
      .y(([, value]) => y(value))
      .context(this.context)(this.wBalLineData)
  }

  drawStressAndIFLabels() {
    const { context } = this
    const stress = getTrainingStress(this.segments, this.ftp) || 0
    const intensity = getIntensityFactor(this.segments, this.ftp) || 0
    const isMore800px = this.chartWidth > 800
    context.beginPath()
    context.fillStyle = "#7e8192"
    context.textAlign = "left"
    context.font = "12px Poppins"
    context.fillText(`INTENSITY: ${intensity}`, this.chartWidth - 200, isMore800px ? 30 : 10)
    context.textAlign = "right"
    context.fillText(`STRESS: ${stress}`, this.chartWidth - 40, isMore800px ? 30 : 10)
    context.closePath()
  }

  drawProgressVerticalLine() {
    const x = this.xFunc(this.watts.length - 1)
    this.context.beginPath()
    this.context.strokeStyle = "#4ea1fe"
    this.context.lineWidth = 0.5
    this.context.moveTo(x, 0)
    this.context.lineTo(x, this.height - this.padding.bottom)
    this.context.stroke()
    this.context.closePath()
  }
}
