const ConfigurationFunction = function () {
  const configuration = {
    development: {
      // apiUrl: "http://localhost:8090",
      websocketsUrl: "wss://websockets.apihost.trainerday.com",
      apiUrl: "https://app.api.trainerday.com",
      sentryUrl: "https://1f265d45d49a4767adf562b318e22a6d@o230172.ingest.sentry.io/6760719",
    },
    stage: {
      websocketsUrl: "wss://localhost:8000",
      apiUrl: "https://api.stage.trainerday.com",
      sentryUrl: "https://1f265d45d49a4767adf562b318e22a6d@o230172.ingest.sentry.io/6760719",
    },
    production: {
      websocketsUrl: "wss://websockets.apihost.trainerday.com",
      apiUrl: "https://app.api.trainerday.com",
      sentryUrl: "https://1f265d45d49a4767adf562b318e22a6d@o230172.ingest.sentry.io/6760719",
    },
  }
  return configuration[process.env.APP_ENV]
}

export default new ConfigurationFunction()
